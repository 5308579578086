import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import first from "lodash/first";
import type { ComponentPropsWithoutRef, FC, MouseEventHandler } from "react";
import { forwardRef } from "react";
import { Typography } from "~/components/typography";
import { USER_BADGE_STYLES } from "~/constants/users";
import type { User } from "~/types/database";
const getHash = (name: string): number => {
  if (name.length > 1) return name.charCodeAt(0) * getHash(name.slice(1));
  return name.charCodeAt(0);
};

//TODO : refactor this component to work with legal person
const UserRoundBadge = forwardRef<HTMLDivElement, {
  user: Pick<User, "first_name" | "last_name">;
}>(function UserBadge({
  user
}, ref) {
  const userBadgeBackground = USER_BADGE_STYLES[getHash(user.last_name + user.first_name) % USER_BADGE_STYLES.length].backgroundColor;
  return <div className={clsx("flex h-10 w-10 shrink-0 items-center justify-center rounded-full p-1", userBadgeBackground)} ref={ref}>
      <div className="font-bold text-white">
        {first(user.first_name)}
        {first(user.last_name)}
      </div>
    </div>;
});
const Badge = forwardRef(function Badge({
  className,
  children,
  onClose,
  rounded = "rounded-2xl",
  fontWeight = "600",
  ...props
}: ComponentPropsWithoutRef<"div"> & {
  onClose?: MouseEventHandler<HTMLButtonElement>;
  rounded?: string;
  fontWeight?: "600" | "700";
}, ref: React.ForwardedRef<HTMLDivElement>) {
  return <div ref={ref} className={clsx("inline-flex w-full max-w-max items-center px-3 py-1.5", rounded, className ?? "bg-neutral-500")} {...props}>
      <Typography.note fontWeight={fontWeight}>{children}</Typography.note>
      {onClose ? <button type="button" onClick={onClose} tabIndex={-1} className="-my-1 -mr-2 ml-1 inline-flex items-center justify-center rounded-full p-1 text-neutral-600 hover:bg-neutral-500 hover:text-neutral-800">
          <XMarkIcon className="h-4 w-4 stroke-[2.5]" />
        </button> : null}
    </div>;
});
const IconBadge: FC<ComponentPropsWithoutRef<"div">> = ({
  children,
  className,
  ...props
}) => {
  return <div className={clsx("flex h-[3.125rem] w-[3.125rem] shrink-0 items-center justify-center rounded-full p-1 bg-blue-grey/80", className)} {...props} data-sentry-component="IconBadge" data-sentry-source-file="badge.tsx">
      {children}
    </div>;
};
export { UserRoundBadge as UserBadge, Badge, IconBadge };